"use client";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Cross2Icon } from "@radix-ui/react-icons";
import { deepPlainEquals } from "@stackframe/stack-shared/dist/utils/objects";
import { Button } from "@stackframe/stack-ui";
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { DownloadIcon } from "lucide-react";
import { DataTableViewOptions } from "./view-options";
export function DataTableToolbar({ table, toolbarRender, showDefaultToolbar, defaultColumnFilters, defaultSorting, }) {
    const isFiltered = !deepPlainEquals(table.getState().columnFilters, defaultColumnFilters);
    const isSorted = !deepPlainEquals(table.getState().sorting, defaultSorting);
    return (_jsxs("div", { className: "flex items-center justify-between", children: [_jsxs("div", { className: "flex items-center gap-2 flex-wrap flex-1", children: [toolbarRender?.(table), (isFiltered || isSorted) && (_jsxs(Button, { variant: "ghost", onClick: () => {
                            table.setColumnFilters(defaultColumnFilters);
                            table.setSorting(defaultSorting);
                        }, className: "h-8 px-2 lg:px-3", children: ["Reset filters", _jsx(Cross2Icon, { className: "ml-2 h-4 w-4" })] }))] }), showDefaultToolbar && (_jsx(_Fragment, { children: _jsxs("div", { className: "flex items-center gap-2 flex-wrap", children: [_jsx(DataTableViewOptions, { table: table }), _jsxs(Button, { variant: "outline", size: "sm", className: "ml-auto hidden h-8 lg:flex", onClick: () => {
                                const csvConfig = mkConfig({
                                    fieldSeparator: ',',
                                    filename: 'data',
                                    decimalSeparator: '.',
                                    useKeysAsHeaders: true,
                                });
                                const renderCellValue = (cell) => {
                                    const rendered = cell.renderValue();
                                    if (rendered === null) {
                                        return undefined;
                                    }
                                    if (['string', 'number', 'boolean', 'undefined'].includes(typeof rendered)) {
                                        return rendered;
                                    }
                                    if (rendered instanceof Date) {
                                        return rendered.toISOString();
                                    }
                                    if (typeof rendered === 'object') {
                                        return JSON.stringify(rendered);
                                    }
                                };
                                const rowModel = table.getCoreRowModel();
                                const rows = rowModel.rows.map(row => Object.fromEntries(row.getAllCells().map(c => [c.column.id, renderCellValue(c)]).filter(([_, v]) => v !== undefined)));
                                if (rows.length === 0) {
                                    alert("No data to export");
                                    return;
                                }
                                const csv = generateCsv(csvConfig)(rows);
                                download(csvConfig)(csv);
                            }, children: [_jsx(DownloadIcon, { className: "mr-2 h-4 w-4" }), "Export CSV"] })] }) }))] }));
}
