import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { cn } from "../..";
import { CheckIcon } from "@radix-ui/react-icons";
import { Badge, Button, Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator, Popover, PopoverContent, PopoverTrigger, Separator } from "@stackframe/stack-ui";
import { ListFilter } from "lucide-react";
export function DataTableFacetedFilter({ column, title, options, }) {
    const facets = column?.getFacetedUniqueValues();
    const selectedValues = new Set(column?.getFilterValue());
    return (_jsxs(Popover, { children: [_jsx(PopoverTrigger, { asChild: true, children: _jsxs(Button, { variant: "outline", size: "sm", className: "h-8 border", children: [_jsx(ListFilter, { className: "mr-2 h-4 w-4 text-gray-500" }), title, selectedValues.size > 0 && (_jsxs(_Fragment, { children: [_jsx(Separator, { orientation: "vertical", className: "mx-2 h-4" }), _jsx(Badge, { variant: "secondary", className: "rounded-sm px-1 font-normal lg:hidden", children: selectedValues.size }), _jsx("div", { className: "hidden space-x-1 lg:flex", children: selectedValues.size > 2 ? (_jsxs(Badge, { variant: "secondary", className: "rounded-sm px-1 font-normal", children: [selectedValues.size, " selected"] })) : (options
                                        .filter((option) => selectedValues.has(option.value))
                                        .map((option) => (_jsx(Badge, { variant: "secondary", className: "rounded-sm px-1 font-normal", children: option.label }, option.value)))) })] }))] }) }), _jsx(PopoverContent, { className: "w-[200px] p-0", align: "start", children: _jsxs(Command, { children: [_jsx(CommandInput, { placeholder: title }), _jsxs(CommandList, { children: [_jsx(CommandEmpty, { children: "No results found." }), _jsx(CommandGroup, { children: options.map((option) => {
                                        const isSelected = selectedValues.has(option.value);
                                        return (_jsxs(CommandItem, { onSelect: () => {
                                                if (isSelected) {
                                                    selectedValues.delete(option.value);
                                                }
                                                else {
                                                    selectedValues.add(option.value);
                                                }
                                                const filterValues = Array.from(selectedValues);
                                                column?.setFilterValue(filterValues.length ? filterValues : undefined);
                                            }, children: [_jsx("div", { className: cn("mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary", isSelected
                                                        ? "bg-primary text-primary-foreground"
                                                        : "opacity-50 [&_svg]:invisible"), children: _jsx(CheckIcon, { className: cn("h-4 w-4") }) }), option.icon && (_jsx(option.icon, { className: "mr-2 h-4 w-4 text-muted-foreground" })), _jsx("span", { children: option.label })] }, option.value));
                                    }) }), selectedValues.size > 0 && (_jsxs(_Fragment, { children: [_jsx(CommandSeparator, {}), _jsx(CommandGroup, { children: _jsx(CommandItem, { onSelect: () => column?.setFilterValue(undefined), className: "justify-center text-center", children: "Clear filters" }) })] }))] })] }) })] }));
}
