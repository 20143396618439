"use client";
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRefIfNeeded } from "@stackframe/stack-shared/dist/utils/react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { cva } from "class-variance-authority";
import { cn } from "../../lib/utils";
const labelVariants = cva("text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-gray-600 dark:text-gray-400");
const Label = forwardRefIfNeeded(({ className, ...props }, ref) => (_jsx("label", { ref: ref, className: cn(labelVariants(), className), ...props })));
Label.displayName = LabelPrimitive.Root.displayName;
const SpanLabel = forwardRefIfNeeded(({ className, ...props }, ref) => (_jsx("span", { ref: ref, className: cn(labelVariants(), className), ...props })));
SpanLabel.displayName = LabelPrimitive.Root.displayName;
export { Label, SpanLabel };
