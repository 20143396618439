var _a, _b, _c;
import { Result } from "./results";
export class WeakRefIfAvailable {
    constructor(value) {
        if (typeof WeakRef === "undefined") {
            this._ref = { deref: () => value };
        }
        else {
            this._ref = new WeakRef(value);
        }
    }
    deref() {
        return this._ref.deref();
    }
}
/**
 * A WeakMap-like object that can be iterated over.
 *
 * Note that it relies on WeakRef, and always falls back to the regular Map behavior (ie. no GC) in browsers that don't support it.
 */
export class IterableWeakMap {
    constructor(entries) {
        this[_a] = "IterableWeakMap";
        const mappedEntries = entries?.map((e) => [e[0], { value: e[1], keyRef: new WeakRefIfAvailable(e[0]) }]);
        this._weakMap = new WeakMap(mappedEntries ?? []);
        this._keyRefs = new Set(mappedEntries?.map((e) => e[1].keyRef) ?? []);
    }
    get(key) {
        return this._weakMap.get(key)?.value;
    }
    set(key, value) {
        const existing = this._weakMap.get(key);
        const updated = { value, keyRef: existing?.keyRef ?? new WeakRefIfAvailable(key) };
        this._weakMap.set(key, updated);
        this._keyRefs.add(updated.keyRef);
        return this;
    }
    delete(key) {
        const res = this._weakMap.get(key);
        if (res) {
            this._weakMap.delete(key);
            this._keyRefs.delete(res.keyRef);
            return true;
        }
        return false;
    }
    has(key) {
        return this._weakMap.has(key) && this._keyRefs.has(this._weakMap.get(key).keyRef);
    }
    *[Symbol.iterator]() {
        for (const keyRef of this._keyRefs) {
            const key = keyRef.deref();
            const existing = key ? this._weakMap.get(key) : undefined;
            if (!key) {
                // This can happen if the key was GCed. Remove it so the next iteration is faster.
                this._keyRefs.delete(keyRef);
            }
            else if (existing) {
                yield [key, existing.value];
            }
        }
    }
}
_a = Symbol.toStringTag;
/**
 * A map that is a IterableWeakMap for object keys and a regular Map for primitive keys. Also provides iteration over both
 * object and primitive keys.
 *
 * Note that, just like IterableWeakMap, older browsers without support for WeakRef will use a regular Map for object keys.
 */
export class MaybeWeakMap {
    constructor(entries) {
        this[_b] = "MaybeWeakMap";
        const entriesArray = [...entries ?? []];
        this._primitiveMap = new Map(entriesArray.filter((e) => !this._isAllowedInWeakMap(e[0])));
        this._weakMap = new IterableWeakMap(entriesArray.filter((e) => this._isAllowedInWeakMap(e[0])));
    }
    _isAllowedInWeakMap(key) {
        return (typeof key === "object" && key !== null) || (typeof key === "symbol" && Symbol.keyFor(key) === undefined);
    }
    get(key) {
        if (this._isAllowedInWeakMap(key)) {
            return this._weakMap.get(key);
        }
        else {
            return this._primitiveMap.get(key);
        }
    }
    set(key, value) {
        if (this._isAllowedInWeakMap(key)) {
            this._weakMap.set(key, value);
        }
        else {
            this._primitiveMap.set(key, value);
        }
        return this;
    }
    delete(key) {
        if (this._isAllowedInWeakMap(key)) {
            return this._weakMap.delete(key);
        }
        else {
            return this._primitiveMap.delete(key);
        }
    }
    has(key) {
        if (this._isAllowedInWeakMap(key)) {
            return this._weakMap.has(key);
        }
        else {
            return this._primitiveMap.has(key);
        }
    }
    *[Symbol.iterator]() {
        yield* this._primitiveMap;
        yield* this._weakMap;
    }
}
_b = Symbol.toStringTag;
/**
 * A map that stores values indexed by an array of keys. If the keys are objects and the environment supports WeakRefs,
 * they are stored in a WeakMap.
 */
export class DependenciesMap {
    constructor() {
        this._inner = { map: new MaybeWeakMap(), hasValue: false, value: undefined };
        this[_c] = "DependenciesMap";
    }
    _valueToResult(inner) {
        if (inner.hasValue) {
            return Result.ok(inner.value);
        }
        else {
            return Result.error(undefined);
        }
    }
    _unwrapFromInner(dependencies, inner) {
        if ((dependencies.length === 0)) {
            return this._valueToResult(inner);
        }
        else {
            const [key, ...rest] = dependencies;
            const newInner = inner.map.get(key);
            if (!newInner) {
                return Result.error(undefined);
            }
            return this._unwrapFromInner(rest, newInner);
        }
    }
    _setInInner(dependencies, value, inner) {
        if (dependencies.length === 0) {
            const res = this._valueToResult(inner);
            if (value.status === "ok") {
                inner.hasValue = true;
                inner.value = value.data;
            }
            else {
                inner.hasValue = false;
                inner.value = undefined;
            }
            return res;
        }
        else {
            const [key, ...rest] = dependencies;
            let newInner = inner.map.get(key);
            if (!newInner) {
                inner.map.set(key, newInner = { map: new MaybeWeakMap(), hasValue: false, value: undefined });
            }
            return this._setInInner(rest, value, newInner);
        }
    }
    *_iterateInner(dependencies, inner) {
        if (inner.hasValue) {
            yield [dependencies, inner.value];
        }
        for (const [key, value] of inner.map) {
            yield* this._iterateInner([...dependencies, key], value);
        }
    }
    get(dependencies) {
        return Result.or(this._unwrapFromInner(dependencies, this._inner), undefined);
    }
    set(dependencies, value) {
        this._setInInner(dependencies, Result.ok(value), this._inner);
        return this;
    }
    delete(dependencies) {
        return this._setInInner(dependencies, Result.error(undefined), this._inner).status === "ok";
    }
    has(dependencies) {
        return this._unwrapFromInner(dependencies, this._inner).status === "ok";
    }
    clear() {
        this._inner = { map: new MaybeWeakMap(), hasValue: false, value: undefined };
    }
    *[Symbol.iterator]() {
        yield* this._iterateInner([], this._inner);
    }
}
_c = Symbol.toStringTag;
