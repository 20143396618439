import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "..";
import { CircleAlert, Info } from "lucide-react";
export function SimpleTooltip(props) {
    const icon = props.type === 'warning' ?
        _jsx(CircleAlert, { className: "w-4 h-4 text-zinc-500" }) :
        props.type === 'info' ?
            _jsx(Info, { className: "w-4 h-4 text-zinc-500" }) :
            null;
    return (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, { asChild: true, children: _jsxs("div", { className: "flex items-center gap-1", children: [props.children, " ", icon] }) }), _jsx(TooltipContent, { children: _jsx("div", { className: "max-w-60 text-center text-wrap whitespace-pre-wrap", children: props.tooltip }) })] }) }));
}
