"use client";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRefIfNeeded } from "@stackframe/stack-shared/dist/utils/react";
import * as SwitchPrimitives from "@radix-ui/react-switch";
import { cn } from "../../lib/utils";
import { runAsynchronouslyWithAlert } from "@stackframe/stack-shared/dist/utils/promises";
import { useAsyncCallback } from "@stackframe/stack-shared/dist/hooks/use-async-callback";
import { Spinner } from "./spinner";
const OriginalSwitch = forwardRefIfNeeded(({ className, ...props }, ref) => (_jsx(SwitchPrimitives.Root, { className: cn("peer inline-flex h-5 w-9 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input", className), ...props, ref: ref, children: _jsx(SwitchPrimitives.Thumb, { className: cn("pointer-events-none block h-4 w-4 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0") }) })));
OriginalSwitch.displayName = SwitchPrimitives.Root.displayName;
const Switch = forwardRefIfNeeded(({ loading: loadingProp, onClick, onCheckedChange, ...props }, ref) => {
    const [handleClick, isLoadingClick] = useAsyncCallback(async (e) => {
        await onClick?.(e);
    }, [onClick]);
    const [handleCheckedChange, isLoadingCheckedChange] = useAsyncCallback(async (checked) => {
        await onCheckedChange?.(checked);
    }, [onCheckedChange]);
    const loading = loadingProp || isLoadingClick || isLoadingCheckedChange;
    return (_jsxs("span", { className: "relative leading-[0]", children: [_jsx(OriginalSwitch, { ...props, ref: ref, onClick: (e) => runAsynchronouslyWithAlert(handleClick(e)), onCheckedChange: (checked) => runAsynchronouslyWithAlert(handleCheckedChange(checked)), disabled: props.disabled || loading, style: {
                    visibility: loading ? "hidden" : "visible",
                    ...props.style,
                } }), _jsx("span", { className: cn("absolute inset-0 flex items-center justify-center", !loading && "hidden"), children: _jsx(Spinner, {}) })] }));
});
Switch.displayName = "Switch";
export { Switch };
