import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRefIfNeeded } from "@stackframe/stack-shared/dist/utils/react";
import { cn } from "../../lib/utils";
const Table = forwardRefIfNeeded(({ className, ...props }, ref) => (_jsx("div", { className: "relative w-full overflow-auto", children: _jsx("table", { ref: ref, className: cn("w-full caption-bottom text-sm", className), ...props }) })));
Table.displayName = "Table";
const TableHeader = forwardRefIfNeeded(({ className, ...props }, ref) => (_jsx("thead", { ref: ref, className: cn("[&_tr]:border-b", className), ...props })));
TableHeader.displayName = "TableHeader";
const TableBody = forwardRefIfNeeded(({ className, ...props }, ref) => (_jsx("tbody", { ref: ref, className: cn("[&_tr:last-child]:border-0", className), ...props })));
TableBody.displayName = "TableBody";
const TableFooter = forwardRefIfNeeded(({ className, ...props }, ref) => (_jsx("tfoot", { ref: ref, className: cn("border-t bg-muted/50 font-medium [&>tr]:last:border-b-0", className), ...props })));
TableFooter.displayName = "TableFooter";
const TableRow = forwardRefIfNeeded(({ className, ...props }, ref) => (_jsx("tr", { ref: ref, className: cn("border-b data-[state=selected]:bg-muted", props.onClick ? "hover:bg-muted/50 cursor-pointer" : "", className), ...props })));
TableRow.displayName = "TableRow";
const TableHead = forwardRefIfNeeded(({ className, ...props }, ref) => (_jsx("th", { ref: ref, className: cn("h-10 px-2 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]", className), ...props })));
TableHead.displayName = "TableHead";
const TableCell = forwardRefIfNeeded(({ className, ...props }, ref) => (_jsx("td", { ref: ref, className: cn("p-2 align-middle [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]", className), ...props })));
TableCell.displayName = "TableCell";
const TableCaption = forwardRefIfNeeded(({ className, ...props }, ref) => (_jsx("caption", { ref: ref, className: cn("mt-4 text-sm text-muted-foreground", className), ...props })));
TableCaption.displayName = "TableCaption";
export { Table, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableCell, TableCaption, };
