"use client";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MixerHorizontalIcon } from "@radix-ui/react-icons";
import { Button, DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@stackframe/stack-ui";
export function DataTableViewOptions({ table, }) {
    return (_jsxs(DropdownMenu, { children: [_jsx(DropdownMenuTrigger, { asChild: true, children: _jsxs(Button, { variant: "outline", size: "sm", className: "ml-auto hidden h-8 lg:flex", children: [_jsx(MixerHorizontalIcon, { className: "mr-2 h-4 w-4" }), "View"] }) }), _jsxs(DropdownMenuContent, { align: "end", children: [_jsx(DropdownMenuLabel, { children: "Toggle columns" }), _jsx(DropdownMenuSeparator, {}), table
                        .getAllColumns()
                        .filter((column) => typeof column.accessorFn !== "undefined" && column.getCanHide())
                        .map((column) => {
                        return (_jsx(DropdownMenuCheckboxItem, { className: "capitalize", checked: column.getIsVisible(), onCheckedChange: (value) => column.toggleVisibility(!!value), children: column.id }, column.id));
                    })] })] }));
}
