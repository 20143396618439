import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRefIfNeeded } from "@stackframe/stack-shared/dist/utils/react";
import React from "react";
import { cn } from "../../lib/utils";
export const Input = forwardRefIfNeeded(({ className, type, prefixItem, ...props }, ref) => {
    const baseClasses = "flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50";
    if (prefixItem) {
        return (_jsxs("div", { className: "flex flex-row items-center", children: [_jsx("div", { className: 'flex self-stretch justify-center items-center text-muted-foreground pl-3 select-none bg-muted/70 pr-3 border-r border-input rounded-l-md', children: prefixItem }), _jsx("input", { type: type, className: cn(baseClasses, 'rounded-l-none', className), ref: ref, ...props })] }));
    }
    else {
        return (_jsx("input", { type: type, className: cn(baseClasses, className), ref: ref, ...props }));
    }
});
Input.displayName = "Input";
export const DelayedInput = forwardRefIfNeeded(({ delay = 500, defaultValue, ...props }, ref) => {
    const [value, setValue] = React.useState(defaultValue ?? "");
    const timeout = React.useRef(null);
    const onChange = (e) => {
        setValue(e.target.value);
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => {
            props.onChange?.(e);
        }, delay);
    };
    return _jsx(Input, { ref: ref, ...props, value: value, onChange: onChange });
});
DelayedInput.displayName = "DelayedInput";
