import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { cn } from "../..";
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@stackframe/stack-ui";
import { EyeOff, ArrowUp, ArrowDown } from "lucide-react";
function Item(props) {
    return (_jsx(DropdownMenuItem, { onClick: props.onClick, children: _jsxs("div", { className: "flex items-center", children: [_jsx(props.icon, { className: "mr-2 h-3.5 w-3.5 text-muted-foreground/70" }), props.children] }) }));
}
export function DataTableColumnHeader({ column, columnTitle, className, }) {
    return (_jsx("div", { className: cn("flex items-center space-x-2", className), children: _jsxs(DropdownMenu, { children: [_jsx(DropdownMenuTrigger, { asChild: true, children: _jsxs(Button, { variant: "ghost", size: "sm", className: "-ml-3 h-8 data-[state=open]:bg-accent", children: [_jsx("span", { children: columnTitle }), column.getIsSorted() === "desc" ? (_jsx(ArrowDown, { className: "ml-2 h-4 w-4" })) : column.getIsSorted() === "asc" ? (_jsx(ArrowUp, { className: "ml-2 h-4 w-4" })) : null] }) }), _jsxs(DropdownMenuContent, { align: "start", className: "stack-scope", children: [column.getCanSort() && (_jsxs(_Fragment, { children: [_jsx(Item, { icon: ArrowUp, onClick: () => column.toggleSorting(false), children: "Asc" }), _jsx(Item, { icon: ArrowDown, onClick: () => column.toggleSorting(true), children: "Desc" })] })), _jsx(Item, { icon: EyeOff, onClick: () => column.toggleVisibility(false), children: "Hide" })] })] }) }));
}
