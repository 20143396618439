import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Label, Textarea, Typography } from "..";
import { CopyButton } from "./copy-button";
export function CopyField(props) {
    return (_jsxs("div", { children: [props.label && (_jsx(Label, { children: props.label })), _jsxs("div", { className: "relative pr-2", children: [_jsx(Textarea, { readOnly: true, value: props.value, style: {
                            height: props.height,
                            fontFamily: props.monospace ? "ui-monospace, monospace" : "inherit",
                            whiteSpace: props.monospace ? "pre" : "normal",
                        } }), _jsx(CopyButton, { content: props.value, className: "absolute right-4 top-2" })] }), props.helper && (_jsx(Typography, { variant: 'secondary', type: 'label', children: props.helper }))] }));
}
