'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import NextLink from 'next/link';
import { cn } from "../../lib/utils";
function Link(props) {
    return _jsx(NextLink, { href: props.href, target: props.target, className: props.className, prefetch: props.prefetch, onClick: props.onClick, children: props.children });
}
function StyledLink(props) {
    return (_jsx(Link, { ...props, className: cn("underline font-medium", props.className), children: props.children }));
}
export { Link, StyledLink };
